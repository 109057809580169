import {
  ADD_LOGIN_BEGIN,
  ADD_LOGIN_FAILURE,
  ADD_LOGIN_SUCCESS,
  FORGOT_PASSWORD_BEGIN,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  RESET_PASSWORD_BEGIN,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  UPDATE_LOGIN_USER_SUCCESS
} from '../constant';

const initialState: any = {
  loading: false,
  data: null,
  error: ''
};

const AuthReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ADD_LOGIN_BEGIN:
      return {
        ...state,
        loading: true
      };
    case ADD_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload
      };
    case ADD_LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case UPDATE_LOGIN_USER_SUCCESS:
      console.log({...action.payload, token: state.data.token}, "test")
      return {
        ...state,
        loading: false,
        data: {...action.payload, token: state.data.token}
      };
    case FORGOT_PASSWORD_BEGIN:
      return {
        ...state,
        loading: true
      };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload
      };
    case FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case RESET_PASSWORD_BEGIN:
      return {
        ...state,
        loading: true
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload
      };
    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    default:
      return state;
  }
};
export default AuthReducer;
