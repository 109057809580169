import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Sidebar from "../../components/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { Pagination } from "@mui/material";
import { getExpenseList } from "../../store/expenses/action";
import SearchBar from "../../components/SearchBar";
import ExpenseListTable from "./TableItem";
import FilterModal from "../../components/FilterModal";

const Container = styled(Box)({
  backgroundColor: "#F4F5FA",
  width: "100%",
  height: "100vh",
});

const WrapperItem = styled(Box)({
  padding: "20px 20px 20px 270px",
  backgroundColor: "#F4F5FA",
});

const SearchBarWrapper = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginTop: "10px",
  "@media (max-width:1024px)": {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    marginTop: "10px",
    justifyContent: "space-between",
  },
});

const PaginationWrapper = styled(Box)({
  display: "flex",
  justifyContent: "right",
  marginTop: "12px",
  fontWeight: "bold",
});

const expenseHeader = [
  "No.",
  "Order ID",
  "Technician Name",
  "Description",
  "Amount (₹)",
  "Advance (₹)",
  "Remaining (₹)",
  "Delivery Date",
  "Status",
];

const ExpenseList = () => {
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filters, setFilters] = useState({
    status: "",
    minExpense: "",
    maxExpense: "",
    fromDate: "",
    toDate: "",
  });

  const dispatch = useDispatch();
  const orderDataPagination = useSelector(
    (state: any) => state?.expenseList?.expenseData
  );
  const auth = useSelector((state: any) => state.auth);
  const expenseList = useSelector((state: any) => state?.expenseList);

  const count = orderDataPagination?.total ? Math.ceil(orderDataPagination?.total / 10) : 1;
  const expenseLoading = expenseList?.loading;

  const isFirstRender = useRef(true);

  const fetchExpenseList = (updatedPage = page, updatedFilters = filters) => {
    dispatch(
      getExpenseList({
        id: auth.data._id,
        page: updatedPage,
        searchTerm,
        rowsPerPage: 10,
        status: updatedFilters.status,
        minExpense: updatedFilters.minExpense,
        maxExpense: updatedFilters.maxExpense,
        fromDate: updatedFilters.fromDate ? updatedFilters.fromDate.toString() : "",
        toDate: updatedFilters.toDate ? updatedFilters.toDate.toString() : "",
      })
    );
  };

  useEffect(() => {
    // if (isFirstRender.current) {
    //   isFirstRender.current = false;
    //   return;
    // }
    console.log("first")
    fetchExpenseList();
  }, [page, filters, searchTerm]);

  const handleChange = (event: any, value: any) => {
    setPage(value);
  };

  const getExpense = (searchTerm: string): any => {
    setSearchTerm(searchTerm);
    setPage(1);
  };

  const handleFilter = (filterData: any) => {
    const updatedFilters = {
      status: filterData.status,
      minExpense: filterData.minExpense,
      maxExpense: filterData.maxExpense,
      fromDate: filterData.fromDate || "",
      toDate: filterData.toDate || "",
    };

    setFilters(updatedFilters);
    setPage(1);
  };

  return (
    <Container>
      <Sidebar />
      <WrapperItem>
        <Typography variant="h5" sx={{ color: "#9155FD", fontWeight: "bold" }}>
          Expenses List
        </Typography>
        <SearchBarWrapper>
          <SearchBar onSearch={getExpense} />
          <FilterModal parentCallback={handleFilter} />
        </SearchBarWrapper>
        <ExpenseListTable
          expenseList={expenseList?.expenseData?.data}
          expenseListHeader={expenseHeader}
          expenseDataLoader={expenseLoading}
          page={page}
        />
        {!expenseLoading &&
          expenseList?.expenseData?.data?.length &&
          expenseList?.expenseData?.data.length > 0 && (
            <PaginationWrapper>
              <Pagination
                sx={{
                  "& .Mui-selected": {
                    backgroundColor: "#9155FD !important",
                    color: "white !important",
                  },
                  "& .Mui-selected:hover": {
                    backgroundColor: "#9155FD !important",
                    color: "white",
                  },
                }}
                count={count}
                page={page}
                onChange={handleChange}
              />
            </PaginationWrapper>
          )}
      </WrapperItem>
    </Container>
  );
};

export default ExpenseList;
