import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import logo from '../../assets/images/logo.svg';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { LoginValidationSchema } from '../../utils/validations';
import { loginAction } from '../../store/auth/action';
import CircularProgress from '@mui/material/CircularProgress';
import IconEye from '../../assets/images/IconEye';
import IconEyeSlash from '../../assets/images/IconEyeSlash';

const Container = styled('div')({
  backgroundColor: '#F4F5FA',
  height: '100vh',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex'
});

const CardContent = styled('div')({
  width: '370px',
  backgroundColor: 'white',
  boxShadow: '0px 2px 10px 0px rgb(58 53 65 / 10%)',
  borderRadius: '8px',
  padding: '40px',
  color: 'rgba(58, 53, 65, 0.87)'
});

const Title = styled('div')({
  marginBottom: '20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

const LinkStyled = styled('div')(({ theme }) => ({
  fontSize: '0.875rem',
  textDecoration: 'none',
  color: theme.palette.primary.main
}));

const StudioAppTitle = styled(Typography)(({ theme }) => ({
  marginLeft: '10px !important',
  fontWeight: '600 !important',
  fontSize: '1.5rem !important'
}));

const SubTitle = styled(Typography)({
  fontWeight: '600 !important',
  marginBottom: '8px !important'
});

const Forgot = styled(Box)({
  margin: '8px 0',
  display: 'flex',
  justifyContent: 'right'
});

const LoginButton = styled(Button)({
  backgroundColor: '#9155FD !important'
});

const PasswordContainer = styled(Box)({
  position: 'relative',
  display: 'flex',
  alignItems: 'center'
});

const EyeIcon = styled('div')({
  position: 'absolute',
  right: '10px',
  cursor: 'pointer'
});

const Login = () => {
  const dispatch = useDispatch();
  const loginLoader = useSelector((state: any) => state?.auth.loading);
  const [showPassword, setShowPassword] = useState(false);

  const initialValues = {
    email: '',
    password: ''
  };

  const handleSubmitLogin = (values: any) => {
    dispatch(loginAction(values));
  };

  return (
    <Container>
      <CardContent>
        <Title>
          <img src={logo} alt='' />
          <StudioAppTitle>STUDIO APP</StudioAppTitle>
        </Title>
        <Box sx={{ mb: 3 }}>
          <SubTitle>Welcome to Studio App!</SubTitle>
          <Typography variant='body2'>
            Please sign-in to your account and start the adventure
          </Typography>
        </Box>
        <Formik
          initialValues={initialValues}
          validationSchema={LoginValidationSchema}
          onSubmit={handleSubmitLogin}
        >
          {(props) => (
            <Form>
              <Field
                as={TextField}
                fullWidth
                name='email'
                label='Email'
                error={props?.touched?.email && Boolean(props?.errors?.email)}
                helperText={<ErrorMessage name='email' />}
                sx={{
                  mb: 3,
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: props?.touched?.email && props?.errors?.email ? 'red' : undefined
                  },
                  '& .MuiFormHelperText-root': { color: 'red' }
                }}
              />

              <PasswordContainer>
                <Field
                  as={TextField}
                  fullWidth
                  variant='outlined'
                  name='password'
                  type={showPassword ? 'text' : 'password'}
                  label='Password'
                  error={props?.touched?.password && Boolean(props?.errors?.password)}
                  helperText={<ErrorMessage name='password' />}
                  sx={{
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: props?.touched?.password && props?.errors?.password ? 'red' : undefined
                    },
                    '& .MuiFormHelperText-root': { color: 'red' }
                  }}
                />
                <EyeIcon onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <IconEyeSlash fill /> : <IconEye fill />}
                </EyeIcon>
              </PasswordContainer>

              <Forgot>
                <Link to='/forgotPassword'>
                  <LinkStyled sx={{ color: '#9155FD' }}>Forgot Password?</LinkStyled>
                </Link>
              </Forgot>

              <Box>
                {!loginLoader ? (
                  <LoginButton fullWidth size='large' type='submit' variant='contained'>
                    Login
                  </LoginButton>
                ) : (
                  <Button fullWidth size='large' type='submit'>
                    <CircularProgress
                      style={{
                        color: '#9155FD',
                        height: '25px',
                        width: '25px'
                      }}
                    />
                  </Button>
                )}
              </Box>
            </Form>
          )}
        </Formik>
      </CardContent>
    </Container>
  );
};

export default Login;
