import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Divider,
  TableContainer,
  Grid,
  styled,
} from '@mui/material';
import { useSelector } from 'react-redux';
import AddPayment from './AddPaymentModal';
import { useDispatch } from 'react-redux';
import {
  addPayment,
  deletePayment,
  editOrderStatus,
  editPayment,
} from '../../../../../store/order/action';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import EditButton from '../../../../../components/EditButton';
import DeleteButton from '../../../../../components/DeleteButton';
import EditPayment from './EditPaymentModal';
import DropdownModal from '../../../../../components/ConfirmationModal';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { formatIndianCurrency } from '../../../../../utils/validations/Helpler';

const paymentListHeader = [
  'Date',
  'Amount (₹)',
  'Remaning payment (₹)',
  'Mode',
  'Action',
];

const ButtonStyle=styled(Button)({
  backgroundColor: '#9155FD !important',
  paddingLeft: '20px',
  paddingRight: '20px',
})

const HeaderWrapper=styled(Box)({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  padding: '10px 30px 0 0',
  marginBottom: '10px',
})

const TableHeader=styled(TableHead)({
  '& .MuiTableCell-root': {
    backgroundColor: 'rgb(249, 250, 252)',
  },
})

const TableText=styled(TableCell)({
  color: 'rgba(58, 53, 65, 0.87)',
 fontWeight: 'bold',
  padding: '8px 4px !important',
})

const TableItemText=styled(TableCell)({
  color: 'black !important',
      fontWeight: '500 !important' as any,
      padding: '8px 4px !important',
})


const PaymentHistory = () => {
  const orderDetail = useSelector((state: any) => state?.orderDetail);
  const [isOpen, setIsOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState(false);
  const [customerPaymentHistory, setCustomerPaymentHistory] = useState<any>([]);
  const [totalPayment, setTotalPayment] = useState();
  const [deleteItemId, setDeleteItemId] = useState<string>('');
  const [lastCustomerPayment, setLastCustomerPayment] = useState<any>({});
  const [customerPayment, setCustomerPayment] = useState([]);
  const [remaningPayment,setRemainingPayment]=useState()
  const [updateSaveData,setUpdateSaveData]=useState()
  const [newUpdateData,setNewUpdateData]=useState()
  const [dropdownConfirmationItemId, setDropdownConfirmationItemId] =
  useState('');
  const [dropdownConfirmationValue, setDropdownConfirmationValue] =
  useState('');
  const dispatch = useDispatch();
  const [save, setSave] = useState(false);
  const [update, setUpdate] = useState(false);
  const [showDropdownConfirmation, setShowDropdownConfirmation] =
  useState(false);
  const location = useLocation();
  const isSuccess = useSelector(
    (state: any) => state?.addCustomerPayment?.isSuccess
  );
  const isEditSuccess = useSelector(
    (state: any) => state?.editCustomerPayment?.isSuccess
  );
  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (isSuccess) {
      handleClose();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isEditSuccess) {
      handleEditClose();
    }
  }, [isEditSuccess]);

  const handleEditClose = () => {
    setIsEdit(false);
  };

  const handleOpen = () => {
    setIsOpen(true);
  };
  const handleCloseCompleted=()=>{
    setDropdownConfirmationItemId('');
    setDropdownConfirmationValue('');
    setShowDropdownConfirmation(false);
  }
 
    const handleChange = (saveData: any, remaningAmount?: any) => {
      dispatch(
        editOrderStatus({ status:'delivered', orderId: location?.state?.orderId })
      );
        if(save ){
          const paymentData = { payload: updateSaveData, _id: location.state};
          dispatch(
            addPayment(paymentData));
            setSave(false)
        }
        if(update){
          const editPaymentData = { payload: newUpdateData, _id: location.state };
          dispatch(
            editPayment(editPaymentData));
            setUpdate(false)
        }

    };
    const saveData = async (saveData: any, remaningAmount?: any) => {
      setUpdateSaveData(saveData)
      setSave(true);
      if (remaningAmount === 0 && !showDropdownConfirmation) {
        setShowDropdownConfirmation(true);
      } else if (!showDropdownConfirmation) {
        dispatch(addPayment({ payload: saveData, _id: location.state }));
      }
    };
    

  const updateData = (updateData: any,remaningAmount?: any) => {
    setNewUpdateData(updateData)
    setUpdate(true)
    if (remaningAmount === 0 && !showDropdownConfirmation) {
      setShowDropdownConfirmation(true);
    } else if (!showDropdownConfirmation) {
      dispatch(editPayment({ payload: updateData, _id: location.state }));
    }
  };

  const orderDetaiLoader = useSelector(
    (state: any) => state?.orderDetail.loading
  );

  const orderDetailData = useSelector((state: any) => state?.orderDetail);
  const editOrderStatusLoader = useSelector(
    (state: any) => state.editOrderStatus.loading
  );

  useEffect(() => {

    if (orderDetail?.orderDetailData) {
      setCustomerPaymentHistory(
        orderDetail?.orderDetailData?.customerPaymentHistory
      );
      setTotalPayment(orderDetail?.orderDetailData?.totalPayment);
     
    }
  }, [orderDetail?.orderDetailData]);

  useEffect(()=>{
    if(orderDetail?.orderDetailData&& orderDetail?.orderDetailData?.customerPaymentHistory?.length > 0 ){
    const lastCustomerPayment =
    orderDetail?.orderDetailData?.customerPaymentHistory[
      orderDetail?.orderDetailData?.customerPaymentHistory.length - 1
    ];
  setLastCustomerPayment(lastCustomerPayment);
    }
  },[orderDetail?.orderDetailData ])

  const navigateToEdit = (data: any) => {
    setIsEdit(true);
    setCustomerPayment(data);
  };

  const handleOpenDeleteModal = (item: any) => {
    setDeleteItemId(item._id);
    setRemainingPayment(item.remaningPayment)
    setShowDeleteModal(true);
  };

  const deletePaymentLoader = useSelector(
    (state: any) => state.deleteCustomerPayment.loading
  );
  useEffect(() => {
    if (!deletePaymentLoader) {
      setShowDeleteModal(false);
    }
  }, [deletePaymentLoader]);
  const handleDeleteModalYes = () => {
    dispatch(deletePayment({payload:{
      id: deleteItemId ,settled:remaningPayment===0?true:false,_id: location.state
    } }));
       const customerPaymentAfterDelete = customerPaymentHistory.filter(
        (item: any) => item._id !== deleteItemId
      );
      const lastCustomerPayment =
      customerPaymentAfterDelete[
        customerPaymentAfterDelete.length - 1
      ];
      setLastCustomerPayment(lastCustomerPayment);
      };

  const handleDeleteModalClose = () => {
    setShowDeleteModal(false);
    setDeleteItemId('');
  };
  useEffect(() => {
    if (!editOrderStatusLoader) {
      setShowDropdownConfirmation(false);
    }
  }, [!editOrderStatusLoader]);

  return (
    <>
      <Box>
        <Divider />
        <HeaderWrapper>
          <Typography
            variant="h5"
            sx={{ color: '#9155FD', fontWeight: 'bold' }}
          >
            Payment History
          </Typography>

          {!orderDetailData.loading &&
          orderDetailData.orderDetailData &&
          lastCustomerPayment.remainingPayment === 0 ? (
            <Button
              size="medium"
              variant="contained"
              color="success"
              endIcon={<CheckCircleIcon style={{ color: 'white' }} />}
            >
              Settled
            </Button>
          ) : (
            <ButtonStyle
              onClick={handleOpen}
              size="medium"
              variant="contained"
            >
              Add Payment
            </ButtonStyle>
          )}
        </HeaderWrapper>
        {!orderDetailData.loading && orderDetailData.orderDetailData ? (
          <TableContainer sx={{ maxHeight: 200 }}>
            <Table size="small" stickyHeader aria-label="sticky table">
              <TableHeader>
                <TableRow>
                  {paymentListHeader?.map((item: string, index: number) => (
                    <TableText
                      key={index}
                      align="center"
                      sx={{ fontSize: '16px', fontWeight: 'bold' }}
                    >
                      {item}
                    </TableText>
                  ))}
                </TableRow>
              </TableHeader>

              {orderDetail?.orderDetailData &&
                customerPaymentHistory &&
                customerPaymentHistory.map((item: any, index: number) => (
                  <TableBody key={index} sx={{ maxHeight: 30 }}>
                    <TableRow hover>
                    <TableItemText align="center">
                      {dayjs(item.paymentDate).format('DD/MM/YYYY')}
                    </TableItemText>
                     <TableItemText align="center">
                      {formatIndianCurrency(item.amount)}
                    </TableItemText>
                    <TableItemText align="center">
                    {formatIndianCurrency(item.remainingPayment)}
                    </TableItemText>
                    <TableItemText align="center">
                    {item.paymentMode.charAt(0).toUpperCase() + item.paymentMode.slice(1)}
                    </TableItemText>
                    {index === 0 ? (
                      <TableItemText align="center">
                        -
                      </TableItemText>
                    ) : (
                      <TableItemText align="center">
                        <EditButton
                          navigateToEdit={() => navigateToEdit(item)}
                        />
                        <DeleteButton
                          handleOpenDeleteModal={() =>
                            handleOpenDeleteModal(item)
                          }
                        />
                      </TableItemText>
                    )}
                    </TableRow>
                   
                  </TableBody>
                ))}
            </Table>
          </TableContainer>
        ) : (
         <TableRow/>
        )}
        {isOpen && (
          <AddPayment
            handleClose={handleClose}
            open={isOpen}
            handleSave={saveData}
            customerPayment={customerPayment}
            customerPaymentHistory={customerPaymentHistory}
          />
        )}
        {isEdit && (
          <EditPayment
            handleClose={handleEditClose}
            open={isEdit}
            handleUpdate={updateData}
            customerPayment={customerPayment}
            customerPaymentHistory={customerPaymentHistory}
          />
        )}
        <DropdownModal
        loading={deletePaymentLoader}
         title=" Are you sure you want to Delete ?"
          isOpen={showDeleteModal}
          handleClose={handleDeleteModalClose}
          handleClickYes={handleDeleteModalYes}
        />

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'end',
            flexDirection: 'column',
            alignItems: 'end',

            marginTop: '20px',
          }}
        >
          <Grid  item md={4} lg={4} container alignItems={'center'}>
            <Grid item md={9}>
              <Typography
                sx={{
                  fontWeight: 'bold',
                  display: 'flex',
                  justifyContent: 'end',
                  alignItems: 'end',
                  textWrap: 'nowrap'
                }}
              >
                Total Payment :
              </Typography>
            </Grid>
            <Grid
              item
              md={2.5}
              sx={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'end',
                textWrap: 'nowrap'
              }}
            >
              {formatIndianCurrency(!orderDetaiLoader &&
                orderDetail?.orderDetailData &&
                orderDetail?.orderDetailData?.totalCustomerPayment)}{' '}
              ₹
            </Grid>
          </Grid>
          <Grid item md={4} lg={4} container alignItems={'center'}>
            <Grid item md={9} >
              <Typography
                sx={{
                  fontWeight: 'bold',
                  display: 'flex',
                  justifyContent: 'end',
                  textWrap: 'nowrap',
                }}
              >
                Total Remaning payment :
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              sx={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'end',
                textWrap: 'nowrap'
              }}
            >
              {formatIndianCurrency(!orderDetaiLoader &&
                orderDetail?.orderDetailData &&
                orderDetail?.orderDetailData
                  ?.totalCustomerRemainingPayment)}{' '}
              ₹
            </Grid>
          </Grid>
        </Box>
      </Box>
      <DropdownModal
      loading={editOrderStatusLoader}
      isOpen={showDropdownConfirmation}
      handleClose={handleCloseCompleted}
      handleClickYes={handleChange}
      title=" Do you want to change the status to delivered?"
      />
    </>
  );
};

export default PaymentHistory;
