import { toast } from 'react-toastify';
import { call, put } from 'redux-saga/effects';
import {SEND_ENVOICE_SUCCESS, SEND_ENVOICE_FAILURE} from '../constant';
import customHistory from '../../routes/history';
import {sendEnvoiceApi} from '../../services/index'


export function* envoiceSaga(action: any): any {
  try {
    const response = yield call(sendEnvoiceApi, action.payload);
    yield put({
      type: SEND_ENVOICE_SUCCESS,
      payload: response.data.data,
    });
    toast(response.data.message);
    customHistory.push('/order');
  } catch (error: any) {
    toast.error(error?.response?.data?.message || "Something went wrong!");
    yield put({
      type: SEND_ENVOICE_FAILURE,
      payload: error.response.data.message,
    });
  }
}


