import { toast } from 'react-toastify';
import { call, put } from 'redux-saga/effects';
import {
  ADD_EXPENSES_FAILURE,
  ADD_EXPENSES_SUCCESS,
  DELETE_ORDER_EXPENSE_FAILURE,
  DELETE_ORDER_EXPENSE_SUCCESS,
  EXPENSE_STATUS_FAILURE,
  EXPENSE_STATUS_SUCCESS,
  GET_EXPENSES_FAILURE,
  GET_EXPENSES_SUCCESS,
  GET_EXPENSE_LIST_FAILURE,
  GET_EXPENSE_LIST_SUCCESS,
  GET_EXPENSE_OPTION_FAILURE,
  GET_EXPENSE_OPTION_SUCCESS,
  IS_LOADING,
  ORDER_DETAIL_SUCCESS,
  USER_DASHBOARD_SUCCESS,
} from '../constant';
import {
  addExpenseApi,
  changeExpenseStatus,
  deleteOrderExpenseApi,
  getExpenseApi,
  getExpenseListApi,
  getExpenseOptionApi,
  getOrderDetail,
  userDasboardApi,
} from '../../services';

export function* getExpenseSaga(action: any): any {
   try {
    const response = yield call(getExpenseApi, action.payload);
    yield put({
      type: GET_EXPENSES_SUCCESS,
      payload: response.data,
    });
  } catch (error: any) {
    yield put({
      type: GET_EXPENSES_FAILURE,
      payload: error.response.data.message,
    });
  }
}

export function* getExpenseOptionSaga(): any {
  try {
    const response = yield call(getExpenseOptionApi);
    yield put({
      type: GET_EXPENSE_OPTION_SUCCESS,
      payload: response.data.data,
    });
  } catch (error: any) {
    yield put({
      type: GET_EXPENSE_OPTION_FAILURE,
      payload: error.response.data.message,
    });
  }
}

export function* addExpenseSaga(action: any): any {
  try {
    const response = yield call(addExpenseApi, action.payload.payload);
    const orderDetailResponse = yield call(getOrderDetail,{_id: action.payload.payload?._id?.orderId})
   
    yield put({
      type: ORDER_DETAIL_SUCCESS,
      payload: orderDetailResponse.data.data,
    });
     yield put({
      type: ADD_EXPENSES_SUCCESS,
      payload: response.data.data,
    });
    toast(response.data.message);
    const expenseDataResponse=yield call(getExpenseListApi,{
      id: action.payload.payload?._id?.orderId, page:'1',searchTerm:'',rowsPerPage:10, status: '', minExpense: '', maxExpense: '', fromDate: '',
      toDate: ''
    })
     
    yield put({
      type: GET_EXPENSE_LIST_SUCCESS,
    payload: expenseDataResponse.data,
    });
    const dashboardDataResponse = yield call(userDasboardApi, {
      frequency: 'daily',
    });
    yield put({
      type: USER_DASHBOARD_SUCCESS,
      payload: dashboardDataResponse.data.data,
    });
  } catch (error: any) {
    toast.error(error.response.data.message || "Something went wrong!");
    yield put({
      type: ADD_EXPENSES_FAILURE,
      payload: error.response.data.message,
    });
    yield put({
      type: IS_LOADING,
      payload: false,
    });
  }
}

export function* getExpenseListSaga(action: any): any {
  try {
    const response = yield call(getExpenseListApi, action.payload);
    yield put({
      type: GET_EXPENSE_LIST_SUCCESS,
      payload: response.data
    });
  } catch (error: any) {
    toast.error(error.response.data.message || "Something went wrong!");
    yield put({
      type: GET_EXPENSE_LIST_FAILURE,
      payload: error.response.data.message
    });
  }
}

export function* expenseStatusSaga(action: any): any {
  try {
    const response = yield call(changeExpenseStatus, action.payload);
      yield put({
      type: EXPENSE_STATUS_SUCCESS,
      payload: response,
    });
    const expenseDataResponse=yield call(getExpenseListApi,{
      id: action.payload.id,page:'1',searchTerm:'',rowsPerPage:10, status: '', minExpense: '', maxExpense: '', fromDate: '',
      toDate: ''
    })
       yield put({
         type: GET_EXPENSE_LIST_SUCCESS,
       payload: expenseDataResponse.data,
       });
    toast(response.data.message);
    
   
  } catch (e: any) {
    toast.error(e.response.data.message || "Something went wrong!");
    yield put({
      type: EXPENSE_STATUS_FAILURE,
      payload: e.response.data.message,
    });
    toast(e.response.data.message);
  }
}


export function* deleteOrderExpenseSaga(action: any): any {
  try {
    const response = yield call(deleteOrderExpenseApi, action.payload.payload);
    const orderDetailResponse = yield call(getOrderDetail,{_id: action.payload.payload?._id})
    yield put({
      type: ORDER_DETAIL_SUCCESS,
      payload: orderDetailResponse.data.data,
    });
     yield put({
      type:DELETE_ORDER_EXPENSE_SUCCESS ,
    });
    toast(response.data.message);
    const dashboardDataResponse = yield call(userDasboardApi, {
      frequency: 'daily',
    });
    yield put({
      type: USER_DASHBOARD_SUCCESS,
      payload: dashboardDataResponse.data.data,
    });
   
  } catch (error: any) {
    toast.error(error.response.data.message || "Something went wrong!");
    yield put({
      type: DELETE_ORDER_EXPENSE_FAILURE,
      payload: error.response.data.message,
    });
  }
}

