export const ADD_LOGIN_BEGIN = 'ADD_LOGIN_BEGIN';
export const ADD_LOGIN_SUCCESS = 'ADD_LOGIN_SUCCESS';
export const ADD_LOGIN_FAILURE = 'ADD_LOGIN_FAILURE';
export const UPDATE_LOGIN_USER_SUCCESS = 'UPDATE_LOGIN_USER_SUCCESS';

export const FORGOT_PASSWORD_BEGIN = 'FORGOT_PASSWORD_BEGIN';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';

export const RESET_PASSWORD_BEGIN = 'RESET_PASSWORD_BEGIN';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const GET_SHOP_BEGIN = 'GET_SHOP_BEGIN';
export const GET_SHOP_SUCCESS = 'GET_SHOP_SUCCESS';
export const GET_SHOP_FAILURE = 'GET_SHOP_FAILURE';

export const ADD_SHOP_BEGIN = 'ADD_SHOP_BEGIN';
export const ADD_SHOP_SUCCESS = 'ADD_SHOP_SUCCESS';
export const ADD_SHOP_FAILURE = 'ADD_SHOP_FAILURE';

export const EDIT_SHOP_BEGIN = 'EDIT_SHOP_BEGIN';
export const EDIT_SHOP_SUCCESS = 'EDIT_SHOP_SUCCESS';
export const EDIT_SHOP_FAILURE = 'EDIT_SHOP_FAILURE';

export const EDIT_SHOP_STATUS_BEGIN = 'EDIT_SHOP_STATUS_BEGIN';
export const EDIT_SHOP_STATUS_SUCCESS = 'EDIT_SHOP_STATUS_SUCCESS';
export const EDIT_SHOP_STATUS_FAILURE = 'EDIT_SHOP_STATUS_FAILURE';

export const DELETE_SHOP_BEGIN = 'DELETE_SHOP_BEGIN';
export const DELETE_SHOP_SUCCESS = 'DELETE_SHOP_SUCCESS';
export const DELETE_SHOP_FAILURE = 'DELETE_SHOP_FAILURE';

export const IS_LOADING = 'IS_LOADING';

export const GET_USERS_BEGIN = 'GET_USERS_BEGIN';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE';

export const EDIT_USERS_BEGIN = 'EDIT_USERS_BEGIN';
export const EDIT_USERS_STATUS_BEGIN = 'EDIT_USERS_STATUS_BEGIN';
export const EDIT_USERS_SUCCESS = 'EDIT_USERS_SUCCESS';
export const EDIT_USERS_FAILURE = 'EDIT_USERS_FAILURE';

export const ADD_USERS_BEGIN = 'ADD_USERS_BEGIN';
export const ADD_USERS_SUCCESS = 'ADD_USERS_SUCCESS';
export const ADD_USERS_FAILURE = 'ADD_USERS_FAILURE';

export const DELETE_USERS_BEGIN = 'DELETE_USERS_BEGIN';
export const DELETE_USERS_SUCCESS = 'DELETE_USERS_SUCCESS';
export const DELETE_USERS_FAILURE = 'DELETE_USERS_FAILURE';

export const LOGOUT = 'LOGOUT';

export const GET_ORDER_BEGIN = 'GET_ORDER_BEGIN';
export const GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS';
export const GET_ORDER_FAILURE = 'GET_ORDER_FAILURE';

export const ADD_ORDER_BEGIN = 'ADD_ORDER_BEGIN';
export const ADD_ORDER_SUCCESS = 'ADD_ORDER_SUCCESS';
export const ADD_ORDER_FAILURE = 'ADD_ORDER_FAILURE';

export const EDIT_ORDER_BEGIN = 'EDIT_ORDER_BEGIN';
export const EDIT_ORDER_SUCCESS = 'EDIT_ORDER_SUCCESS';
export const EDIT_ORDER_FAILURE = 'EDIT_ORDER_FAILURE';

export const EDIT_ORDER_STATUS_BEGIN = 'EDIT_ORDER_STATUS_BEGIN';
export const EDIT_ORDER_STATUS_SUCCESS = 'EDIT_ORDER_STATUS_SUCCESS';
export const EDIT_ORDER_STATUS_FAILURE = 'EDIT_ORDER_STATUS_FAILURE';

export const DELETE_ORDER_BEGIN = 'DELETE_ORDER_BEGIN';
export const DELETE_ORDER_SUCCESS = 'DELETE_ORDER_SUCCESS';
export const DELETE_ORDER_FAILURE = 'DELETE_ORDER_FAILURE';

export const GET_TRANSACTION_BEGIN = 'GET_TRANSACTION_BEGIN';
export const GET_TRANSACTION_SUCCESS = 'GET_TRANSACTION_SUCCESS';
export const GET_TRANSACTION_FAILURE = 'GET_TRANSACTION_FAILURE';

export const ADD_TRANSACTION_BEGIN = 'ADD_TRANSACTION_BEGIN';
export const ADD_TRANSACTION_SUCCESS = 'ADD_TRANSACTION_SUCCESS';
export const ADD_TRANSACTION_FAILURE = 'ADD_TRANSACTION_FAILURE';

export const EDIT_TRANSACTION_BEGIN = 'EDIT_TRANSACTION_BEGIN';
export const EDIT_TRANSACTION_SUCCESS = 'EDIT_TRANSACTION_SUCCESS';
export const EDIT_TRANSACTION_FAILURE = 'EDIT_TRANSACTION_FAILURE';

export const DELETE_TRANSACTION_BEGIN = 'DELETE_TRANSACTION_BEGIN';
export const DELETE_TRANSACTION_SUCCESS = 'DELETE_TRANSACTION_SUCCESS';
export const DELETE_TRANSACTION_FAILURE = 'DELETE_TRANSACTION_FAILURE';

export const GET_EMPLOYEE_BEGIN = 'GET_EMPLOYEE_BEGIN';
export const GET_EMPLOYEE_SUCCESS = 'GET_EMPLOYEE_SUCCESS';
export const GET_EMPLOYEE_FAILURE = 'GET_EMPLOYEE_FAILURE';

export const EDIT_EMPLOYEE_BEGIN = 'EDIT_EMPLOYEE_BEGIN';
export const EDIT_EMPLOYEE_SUCCESS = 'EDIT_EMPLOYEE_SUCCESS';
export const EDIT_EMPLOYEE_FAILURE = 'EDIT_EMPLOYEE_FAILURE';

export const ADD_EMPLOYEE_BEGIN = 'ADD_EMPLOYEE_BEGIN';
export const ADD_EMPLOYEE_SUCCESS = 'ADD_EMPLOYEE_SUCCESS';
export const ADD_EMPLOYEE_FAILURE = 'ADD_EMPLOYEE_FAILURE';

export const DELETE_EMPLOYEE_BEGIN = 'DELETE_EMPLOYEE_BEGIN';
export const DELETE_EMPLOYEE_SUCCESS = 'DELETE_EMPLOYEE_SUCCESS';
export const DELETE_EMPLOYEE_FAILURE = 'DELETE_EMPLOYEE_FAILURE';

export const EDIT_EMPLOYEE_STATUS_BEGIN = 'EDIT_EMPLOYEE_STATUS_BEGIN';
export const EDIT_EMPLOYEE_STATUS_SUCCESS = 'EDIT_EMPLOYEE_STATUS_SUCCESS';
export const EDIT_EMPLOYEE_STATUS_FAILURE = 'DIT_EMPLOYEE_STATUS_FAILURE';

export const SEND_ENVOICE_BEGIN = 'SEND_ENVOICE_BEGIN';
export const SEND_ENVOICE_SUCCESS = 'SEND_ENVOICE_SUCCESS';
export const SEND_ENVOICE_FAILURE = 'SEND_ENVOICE_FAILURE';

export const ADD_EXPENSES_BEGIN = 'ADD_EXPENSES_BEGIN';
export const ADD_EXPENSES_SUCCESS = 'ADD_EXPENSES_SUCCESS';
export const ADD_EXPENSES_FAILURE = 'ADD_EXPENSES_FAILURE';

export const GET_EXPENSES_BEGIN = 'GET_EXPENSES_BEGIN';
export const GET_EXPENSES_SUCCESS = 'GET_EXPENSES_SUCCESS';
export const GET_EXPENSES_FAILURE = 'GET_EXPENSES_FAILURE';

export const ADD_TECHNICIAN_BEGIN = 'ADD_TECHNICIAN_BEGIN';
export const ADD_TECHNICIAN_SUCCESS = 'ADD_TECHNICIAN_SUCCESS';
export const ADD_TECHNICIAN_FAILURE = 'ADD_TECHNICIAN_FAILURE';

export const DELETE_TECHNICIAN_BEGIN = 'DELETE_TECHNICIAN_BEGIN';
export const DELETE_TECHNICIAN_SUCCESS = 'DELETE_TECHNICIAN_SUCCESS';
export const DELETE_TECHNICIAN_FAILURE = 'DELETE_TECHNICIAN_FAILUR';

export const EDIT_TECHNICIAN_BEGIN = ' EDIT_TECHNICIAN_BEGIN ';
export const EDIT_TECHNICIAN_SUCCESS = 'EDIT_TECHNICIAN_SUCCESSS';
export const EDIT_TECHNICIAN_FAILURE = 'EDIT_TECHNICIAN_FAILURE';

export const GET_TECHNICIAN_BEGIN = 'GET_TECHNICIAN_BEGIN';
export const GET_TECHNICIAN_SUCCESS = 'GET_TECHNICIAN_SUCCESS';
export const GET_TECHNICIAN_FAILURE = 'GET_TECHNICIAN_FAILURE';

export const EDIT_TECHNICIAN_STATUS_BEGIN = 'EDIT_TECHNICIAN_STATUS_BEGIN';
export const EDIT_TECHNICIAN_STATUS_SUCCESS = 'EDIT_TECHNICIAN_STATUS_SUCCESS';
export const EDIT_TECHNICIAN_STATUS_FAILURE = 'EDIT_TECHNICIAN_STATUS_FAILURE';

export const GET_TECHNICIAN_NAMES_BEGIN = 'GET_TECHNICIAN_NAMES_BEGIN';
export const GET_TECHNICIAN_NAMES_SUCCCESS = 'GET_TECHNICIAN_NAMES_SUCCCESS';
export const GET_TECHNICIAN_NAMES_FAILURE = 'GET_TECHNICIAN_NAMES_FAILURE';

export const GET_EXPENSE_OPTION_BEGIN = 'GET_EXPENSE_OPTION_BEGIN';
export const GET_EXPENSE_OPTION_SUCCESS = 'GET_EXPENSE_OPTION_SUCCESS';
export const GET_EXPENSE_OPTION_FAILURE = 'GET_EXPENSE_OPTION_FAILURE';

export const GET_EXPENSE_LIST_BEGIN = 'GET_EXPENSE_LIST_BEGIN ';
export const GET_EXPENSE_LIST_SUCCESS = 'GET_EXPENSE_LIST_SUCCESS';
export const GET_EXPENSE_LIST_FAILURE = 'GET_EXPENSE_LIST_FAILURE';


export const EXPENSE_STATUS_BEGIN = 'EXPENSE_STATUS_BEGIN ';
export const EXPENSE_STATUS_SUCCESS = 'EXPENSE_STATUS_SUCCESS';
export const EXPENSE_STATUS_FAILURE = 'EXPENSE_STATUS_FAILURE';


export const ORDER_DETAIL_BEGIN = 'ORDER_DETAIL_BEGIN';
export const ORDER_DETAIL_SUCCESS = 'ORDER_DETAIL_SUCCESS';
export const ORDER_DETAIL_FAILURE = 'ORDER_DETAIL_FAILURE';


export const ADD_CUSTOMER_PAYMENT_BEGIN = 'ADD_CUSTOMER_PAYMENT_BEGIN';
export const ADD_CUSTOMER_PAYMENT_SUCCESS = 'ADD_CUSTOMER_PAYMENT_SUCCESS';
export const ADD_CUSTOMER_PAYMENT_FAILURE = 'ADD_CUSTOMER_PAYMENT_FAILURE';


export const DELETE_CUSTOMER_PAYMENT_BEGIN = 'DELETE_CUSTOMER_PAYMENT_BEGIN';
export const DELETE_CUSTOMER_PAYMENT_SUCCESS = 'DELETE_CUSTOMER_PAYMENT_SUCCESS';
export const DELETE_CUSTOMER_PAYMENT_FAILURE = 'DELETE_CUSTOMER_PAYMENT_FAILURE';

export const EDIT_CUSTOMER_PAYMENT_BEGIN = 'EDIT_CUSTOMER_PAYMENT_BEGIN';
export const EDIT_CUSTOMER_PAYMENT_SUCCESS = 'EDIT_CUSTOMER_PAYMENT_SUCCESS';
export const EDIT_CUSTOMER_PAYMENT_FAILURE = 'EDIT_CUSTOMER_PAYMENT_FAILURE';

export const EDIT_CUSTOMER_ORDER_BEGIN = 'EDIT_CUSTOMER_ORDER_BEGIN';
export const EDIT_CUSTOMER_ORDER_SUCCESS = 'EDIT_CUSTOMER_ORDER_SUCCESS';
export const EDIT_CUSTOMER_ORDER_FAILURE = 'EDIT_CUSTOMER_ORDER_FAILURE';

export const ADD_TECHNICIAN_PAYMENT_BEGIN = 'ADD_TECHNICIAN_PAYMENT_BEGIN';
export const ADD_TECHNICIAN_PAYMENT_SUCCESS = 'ADD_TECHNICIAN_PAYMENT_SUCCESS';
export const ADD_TECHNICIAN_PAYMENT_FAILURE = 'ADD_TECHNICIAN_PAYMENT_FAILURE';

export const USER_DASHBOARD_BEGIN = 'USER_DASHBOARD_BEGIN';
export const USER_DASHBOARD_SUCCESS = 'USER_DASHBOARD_SUCCESS';
export const USER_DASHBOARD_FAILURE = 'USER_DASHBOARD_FAILURE';

export const DELETE_TECHNICIAN_PAYMENT_BEGIN = 'DELETE_TECHNICIAN_PAYMENT_BEGIN';
export const DELETE_TECHNICIAN_PAYMENT_SUCCESS = 'DELETE_TECHNICIAN_PAYMENT_SUCCESS';
export const DELETE_TECHNICIAN_PAYMENT_FAILURE = 'DELETE_TECHNICIAN_PAYMENT_FAILURE';

export const EDIT_TECHNICIAN_PAYMENT_BEGIN = 'EDIT_TECHNICIAN_PAYMENT_BEGIN';
export const EDIT_TECHNICIAN_PAYMENT_SUCCESS = 'EDIT_TECHNICIAN_PAYMENT_SUCCESS';
export const EDIT_TECHNICIAN_PAYMENT_FAILURE = 'EDIT_TECHNICIAN_PAYMENT_FAILURE';

export const DELETE_ORDER_EXPENSE_BEGIN = 'DELETE_ORDER_EXPENSE_BEGIN';
export const DELETE_ORDER_EXPENSE_SUCCESS = 'DELETE_ORDER_EXPENSE_SUCCESS';
export const DELETE_ORDER_EXPENSE_FAILURE = 'DELETE_ORDER_EXPENSE_FAILURE';

export const DELETE_IMAGE_BEGIN = 'DELETE_IMAGE_BEGIN';
export const DELETE_IMAGE_SUCCESS = 'DELETE_IMAGE_SUCCESS';
export const DELETE_IMAGE_FAILURE = 'DELETE_IMAGE_FAILURE';

export const DELETE_ORDER_IMAGE_BEGIN = 'DELETE_ORDER_IMAGE_BEGIN';
export const DELETE_ORDER_IMAGE_SUCCESS = 'DELETE_ORDER_IMAGE_SUCCESS';
export const DELETE_ORDER_IMAGE_FAILURE = 'DELETE_ORDER_IMAGE_FAILURE';

export const SHOP_DELETE_IMAGE_BEGIN = 'SHOP_DELETE_IMAGE_BEGIN';
export const SHOP_DELETE_IMAGE_SUCCESS = 'SHOP_DELETE_IMAGE_SUCCESS';
export const SHOP_DELETE_IMAGE_FAILURE = 'SHOP_DELETE_IMAGE_FAILURE';

export const CREATE_MEASUREMENT_BEGIN='CREATE_MEASUREMENT_BEGIN';
export const CREATE_MEASUREMENT_SUCCESS='CREATE_MEASUREMENT_SUCCESS';
export const CREATE_MEASUREMENT_FAILURE='CREATE_MEASUREMENT_FAILURE'

export const GET_MEASUREMENT_BEGIN='GET_MEASUREMENT_BEGIN';
export const GET_MEASUREMENT_SUCCESS='GET_MEASUREMENT_SUCCESS';
export const GET_MEASUREMENT_FAILURE='GET_MEASUREMENT_FAILURE'

export const DELETE_MEASUREMENT_BEGIN='DELETE_MEASUREMENT_BEGIN';
export const DELETE_MEASUREMENT_SUCCESS='DELETE_MEASUREMENT_SUCCESS';
export const DELETE_MEASUREMENT_FAILURE='DELETE_MEASUREMENT_FAILURE'

export const EDIT_MEASUREMENT_BEGIN='EDIT_MEASUREMENT_BEGIN';
export const EDIT_MEASUREMENT_SUCCESS='EDIT_MEASUREMENT_SUCCESS';
export const EDIT_MEASUREMENT_FAILURE='EDIT_MEASUREMENT_FAILURE'
