import { toast } from 'react-toastify';
import { call, put } from 'redux-saga/effects';
import {
  addShop,
  deleteShop,
  editShop,
  editShopStatus,
  getShop,
} from '../../services';
import {
  ADD_SHOP_FAILURE,
  GET_SHOP_SUCCESS,
  GET_SHOP_FAILURE,
  EDIT_SHOP_FAILURE,
  EDIT_SHOP_SUCCESS,
  DELETE_SHOP_SUCCESS,
  DELETE_SHOP_FAILURE,
  ADD_SHOP_SUCCESS,
  IS_LOADING,
  EDIT_SHOP_STATUS_SUCCESS,
  EDIT_SHOP_STATUS_FAILURE,

} from '../constant';
import customHistory from '../../routes/history';

export function* getShopSaga(action: any): any {
  try {
    const response = yield call(getShop, action.payload);
      yield put({
        type: GET_SHOP_SUCCESS,
        payload: response.data,
      });
   
  } catch (error: any) {
    toast.error(error?.response?.data?.message || "Something went wrong!");
    yield put({
      type: GET_SHOP_FAILURE,
      payload: error.response.data.message,
    });
  }
}

export function* addShopSaga(action: any): any {
  try {
    yield put({
      type: IS_LOADING,
      payload: true,
    });
    const response = yield call(addShop, action.payload.payload);
    const shopDataResponse=yield call(getShop,{
      id:action.payload.id,page:'1',searchTerm:'',rowsPerPage:10
    })
       yield put({
         type: GET_SHOP_SUCCESS,
       payload: shopDataResponse.data,
       });
    yield put({
      type: ADD_SHOP_SUCCESS,
      payload: response.data.data,
    });
    toast(response.data.message);
    yield put({
      type: IS_LOADING,
      payload: false,
    });
    customHistory.push('/shops');
  } catch (error: any) {
    toast.error(error?.response?.data?.message || "Something went wrong!");
    yield put({
      type: ADD_SHOP_FAILURE,
      payload: error.response.data.message,
    });
    yield put({
      type: IS_LOADING,
      payload: false,
    });
  }
}

export function* editShopSaga(action: any): any {
  try {
    yield put({
      type: IS_LOADING,
      payload: true,
    });
    const response = yield call(editShop, action.payload);
    const shopDataResponse=yield call(getShop,{
      id:action.payload.id,page:'1',searchTerm:'',rowsPerPage:10
    })
       yield put({
         type: GET_SHOP_SUCCESS,
       payload: shopDataResponse.data,
       });
    yield put({
      type: EDIT_SHOP_SUCCESS,
      payload: response.data.data,
    });
    toast(response.data.message);
    yield put({
      type: IS_LOADING,
      payload: false,
    });
    customHistory.push('/shops');
  } catch (e: any) {
    toast.error(e?.response?.data?.message || "Something went wrong!");
    yield put({
      type: EDIT_SHOP_FAILURE,
      payload: e.response.data.message,
    });
    yield put({
      type: IS_LOADING,
      payload: false,
    });
  }
}

export function* editShopStatusSaga(action: any): any {
  try {
    const response = yield call(editShopStatus, action.payload);
    const shopDataResponse=yield call(getShop,{
      id:action.payload.id,page:'1',searchTerm:'',rowsPerPage:10
    })
       yield put({
         type: GET_SHOP_SUCCESS,
       payload: shopDataResponse.data,
       });
    yield put({
      type: EDIT_SHOP_STATUS_SUCCESS,
      payload: response,
    });
    toast(response.data.message);
  } catch (e: any) {
    toast.error(e?.response?.data?.message || "Something went wrong!");
    yield put({
      type: EDIT_SHOP_STATUS_FAILURE,
      payload: e.response.data.message,
    });
    toast(e.response.data.message);
  }
}

export function* deleteShopSaga(action: any): any {
  try {
    const response = yield call(deleteShop, action.payload.payload);
    const shopDataResponse=yield call(getShop,{
      id:action.payload.id,page:'1',searchTerm:'',rowsPerPage:10
    })
       yield put({
         type: GET_SHOP_SUCCESS,
       payload: shopDataResponse.data,
       });
    yield put({
      type: DELETE_SHOP_SUCCESS,
    });

    toast(response.data.message)
  } catch (error: any) {
    toast.error(error?.response?.data?.message || "Something went wrong!");
    yield put({
      type: DELETE_SHOP_FAILURE,
      payload: error.response.data.message,
    });
  }
}
