import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Avatar,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  CircularProgress,
  Paper,
} from '@mui/material';
import Sidebar from '../../components/Sidebar';
import DefaultAvatar from '../../assets/images/avtar.png';
import Pagination from '@mui/material/Pagination';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import DeleteButton from '../../components/DeleteButton';
import EditButton from '../../components/EditButton';
import { deleteTechnician, editTechnicianStatus, getTechnician } from '../../store/technician/action';
import DropdownModal from '../../components/ConfirmationModal';
import SearchBar from '../../components/SearchBar';
import TechnicianModal from './ViewDetailModal';
import VisibilityIcon from '@mui/icons-material/Visibility';
import NoDataImage from '../../components/NoDataImage';
import { rowPerPage } from '../../utils';

const options = [
  { value: 'active', label: 'Active' },
  { value: 'inactive', label: 'InActive' },
];

const Container = styled(Box)({
  backgroundColor: '#F4F5FA',
  width: '100%',
  height: '100vh',
});

const WrapperItem = styled(Box)({
  padding: '20px 20px 20px 270px',
  backgroundColor: '#F4F5FA'
});

const SearchBarWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: '10px',
  '@media (max-width:1024px)': {
    display: 'flex',
    flexDirection: 'column', 
    alignItems: 'start',
    marginTop: '10px',
    justifyContent: 'space-between'  },
});

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  borderRadius: theme.spacing(1),
  boxShadow: 'rgba(58, 53, 65, 0.1) 0px 2px 10px 0px',
}));

const StyledTableHeadCell = styled(TableCell)(() => ({
  background: 'rgb(249, 250, 252)',
  color: 'rgba(58, 53, 65, 0.87)',
  fontWeight: 'bold',
  fontSize: '16px',
  textAlign: 'center',
  textWrap: 'nowrap'
}));

const TableDataWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
  position: 'absolute',
  alignItems: 'center',
  height: '60%',
  width: '-webkit-fill-available',
});

const PaginationWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'right',
  marginTop: '12px',
  fontWeight: 'bold',
});

const columns = [
  'No.',
  'Profile',
  'First Name',
  'Last Name',
  'Email',
  'Address',
  'City',
  'Contact',
  'Status',
  'Action',
];

const TechnicianList = () => {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [deleteItemId, setDeleteItemId] = useState<string>('');
  const [page, setPage] = useState(1);
  const [dropdownValues, setDropdownValues] = useState({});
  const [showViewModal, setShowViewModal] = useState<boolean>(false);
  const [technicianData, setTechnicianData] = useState([])
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [ModalTechnicianData, setModalTechnicianData] =
    useState<null | HTMLElement>(null);

  const technician = useSelector(
    (state: any) => state?.technician
  );

    useEffect(() => {
  if(technician?.technicianData?.length===0){
    dispatch(
      getTechnician({ id: auth.data._id, page, searchTerm, rowsPerPage: 10 })
    );
    }
  }, [page]);

  useEffect(() => {
    if (technician?.technicianData?.data) {
      setTechnicianData(technician?.technicianData?.data)
    }
  }, [technician?.technicianData?.data])


  const [searchTerm, setSearchTerm] = useState<string>('');
  const technicianLoader = useSelector(
    (state: any) => state?.technician.loading
  );

  const technicianPagination = useSelector(
    (state: any) => state?.technician?.technicianData
  );
  const auth = useSelector((state: any) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const editTechnicianStatusLoader = useSelector(
    (state: any) => state.editTechnicianStatus.loading
  );

  const count = Math.ceil(technicianPagination?.total / 10);
  const getTechnicianList = (searchTerm: string): any => {
    setPage(1);
    dispatch(
      getTechnician({ id: auth.data._id, page: 1, searchTerm, rowsPerPage: 10 })
    );
    setSearchTerm(searchTerm);
    setDropdownValues({});
  };
  const [showDropdownConfirmation, setShowDropdownConfirmation] =
    useState(false);
  const [dropdownConfirmationItemId, setDropdownConfirmationItemId] =
    useState('');
  const [dropdownConfirmationValue, setDropdownConfirmationValue] =
    useState('');
  const [dropdownModalValues, setDropdownModalValues] = useState<any>({
    e: '',
    row: '',
  });
  const handleCloseDropdownConfirmationModal = () => {
    setDropdownConfirmationItemId('');
    setDropdownConfirmationValue('');
    setShowDropdownConfirmation(false);
  };


  const handleViewModel = (item: any) => {
    setModalTechnicianData(item);
    setShowViewModal(true);
    setAnchorEl(null);
  };

  const handleDeleteModalYes = () => {
    dispatch(deleteTechnician({ payload: {
          id: deleteItemId,
          authId:auth.data._id
        },}));
  };

  const handleOpenDeleteModal = (deleteId: string) => {
    setDeleteItemId(deleteId);
    setShowDeleteModal(true);
  };

  const handleClose = () => {
    setShowDeleteModal(false);
    setDeleteItemId('');
  };
  const navigateToEdit = (data: any) => {
    navigate(`/technician/edit/${data.shopId}`, { state: data });
  };
  const handleCloseModal = () => {
    setShowViewModal(false);
  };
  const onClose = () => {
    setShowViewModal(false);
  };
  const handleChange = (event: any, value: any) => {
    dispatch(
      getTechnician({ id: auth.data._id, page: value, searchTerm, rowsPerPage: 10 })
    );
    setPage(value);
  };

  useEffect(() => {
    if (!editTechnicianStatusLoader) {
      setShowDropdownConfirmation(false);
    }
  }, [!editTechnicianStatusLoader]);

  const switchHandler = (event: any, item: any) => {
    setShowDropdownConfirmation(true);
    dispatch(
      editTechnicianStatus({ status: event.target.value,id:item._id })
    );
  };
  
  const handleDropdownConfirmationAction = (e: any, item: any) => {
    setDropdownModalValues({ e, item });
    setShowDropdownConfirmation(true);
  };

  const deleteTechnicianLoader = useSelector(
    (state: any) => state.deleteTechnician.loading
  );

  
  useEffect(() => {
    if (!deleteTechnicianLoader) {
      setShowDeleteModal(false);
      setPage(1)
    }
  }, [!deleteTechnicianLoader]);



  return (
    <Container>
      <Sidebar />
      <WrapperItem>
        <Typography variant='h5' sx={{ color: '#9155FD', fontWeight: 'bold' }}>
          Technicians List
        </Typography>
        <SearchBarWrapper>
          <SearchBar onSearch={getTechnicianList} />
          <Link to='/technician/add'>
            <Button
              size='medium'
              sx={{ backgroundColor: '#9155FD !important', fontSize: '16px','@media (max-width: 1024px)': { marginTop:'10px' } }}
              variant='contained'
            >
              Create
            </Button>
          </Link>
        </SearchBarWrapper>
        <Paper sx={{ overflow: "hidden", mt: 2 }}>
          <StyledTableContainer>
            <Table stickyHeader size='small'>
              <TableHead>
                <TableRow>
                  {columns.map((column, index) => (
                    <StyledTableHeadCell key={index} >
                      {column}
                    </StyledTableHeadCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {!technicianLoader ? (
                  technicianData.length === 0 ? (
                    <TableRow>
                    <TableCell>
                     <NoDataImage/>
                    </TableCell>
                  </TableRow>
                  ) : (
                    <TableRow/>
                  )
                ) : (
                  <TableDataWrapper>
                    <CircularProgress color='inherit' />
                  </TableDataWrapper>
                )}
                {!technicianLoader
                  ?
                  technicianData?.map((row: any, index: number) => {
                    return (
                      <TableRow tabIndex={-1} key={index}>
                        <TableCell align='center'>{( page - 1 ) * rowPerPage + index + 1}</TableCell>
                        <TableCell align='center' width={60}>
                          <Box display='flex' justifyContent='center'>
                            <Avatar
                              src={row.profilePic || DefaultAvatar}
                              alt='customer-profile'
                            />
                          </Box>
                        </TableCell>
                        <TableCell align='center'>{row.firstName}</TableCell>
                        <TableCell align='center'>{row.lastName}</TableCell>
                        <TableCell align='center'>{row.email || 'N/A'}</TableCell>
                        <TableCell align='center'>{row.address}</TableCell>
                        <TableCell align='center'>{row.city}</TableCell>
                        <TableCell align='center'>
                          {row.mobileNumber}
                        </TableCell>
                        <TableCell sx={{ align: 'center', textAlign: 'center' }}>
                          <FormControl sx={{ m: 0, minWidth: 100 }} size="small">
                            <Select
                              labelId="demo-select-small-label"
                              id="demo-select-small"
                              sx={{ align: 'center',minWidth:'130px' }}
                              // @ts-ignore
                              value={row.status}
                              label='status'
                              onChange={(e) => {
                                handleDropdownConfirmationAction(e, row);
                              }}
                            >
                              {options.map((data: any, index: any) => (
                                <MenuItem value={data.value} key={index}>
                                  {data.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </TableCell>
                        <TableCell
                          align='center'
                          sx={{ whiteSpace: 'nowrap', alignItems: 'center' }}
                        >
                          <EditButton
                            navigateToEdit={() => navigateToEdit(row)}
                          />
                          <DeleteButton
                            handleOpenDeleteModal={() =>
                              handleOpenDeleteModal(row._id)
                            }
                          />
                          <VisibilityIcon
                            sx={{ pl: '6px' }}
                            onClick={() => handleViewModel(row)}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })
                  : ''}
              </TableBody>
            </Table>
          </StyledTableContainer>
        </Paper>
        {!technicianLoader && technicianData.length > 0 &&<PaginationWrapper>
          <Pagination 
          sx={{
            '& .Mui-selected': {
              backgroundColor: '#9155FD !important',
              color:'white',
             },
            '& .Mui-selected:hover': {
              backgroundColor: '#9155FD !important',
              color:'white',
             },
          }}
          count={count} page={page} onChange={handleChange} />
        </PaginationWrapper>}
      </WrapperItem>
      <DropdownModal
      loading={editTechnicianStatusLoader}
        title=' Are you sure you want to change the status?'
        isOpen={showDropdownConfirmation}
        handleClose={handleCloseDropdownConfirmationModal}
        handleClickYes={switchHandler}
        dropdownModalValues={dropdownModalValues}
      />
      <DropdownModal
      loading={deleteTechnicianLoader}
        title=' Are you sure you want to Delete ?'
        isOpen={showDeleteModal}
        handleClose={handleClose}
        handleClickYes={handleDeleteModalYes}
      />
      <TechnicianModal
        isOpen={showViewModal}
        handleClose={handleCloseModal}
        onClose={onClose}
        ModalTechnicianData={ModalTechnicianData}
      />
    </Container>
  );
};

export default TechnicianList;
