import { ADD_LOGIN_BEGIN, FORGOT_PASSWORD_BEGIN, LOGOUT, RESET_PASSWORD_BEGIN, UPDATE_LOGIN_USER_SUCCESS } from '../constant';

export const loginAction = (payload: any) => ({
  type: ADD_LOGIN_BEGIN,
  payload,
});

export const logout = () => ({
  type: LOGOUT,
});

export const forgotPasswordAction = (payload: any) => ({
  type: FORGOT_PASSWORD_BEGIN,
  payload,
})

export const resetPasswordAction = (payload: any) => ({
  type: RESET_PASSWORD_BEGIN,
  payload,
})