import { call, put } from 'redux-saga/effects';
import store from '..';
import customHistory from '../../routes/history';
import {
  ADD_TECHNICIAN_FAILURE,
  ADD_TECHNICIAN_PAYMENT_FAILURE,
  ADD_TECHNICIAN_PAYMENT_SUCCESS,
  ADD_TECHNICIAN_SUCCESS,
  DELETE_TECHNICIAN_FAILURE,
  DELETE_TECHNICIAN_PAYMENT_FAILURE,
  DELETE_TECHNICIAN_PAYMENT_SUCCESS,
  DELETE_TECHNICIAN_SUCCESS,
  EDIT_TECHNICIAN_FAILURE,
  EDIT_TECHNICIAN_PAYMENT_FAILURE,
  EDIT_TECHNICIAN_PAYMENT_SUCCESS,
  EDIT_TECHNICIAN_STATUS_FAILURE,
  EDIT_TECHNICIAN_STATUS_SUCCESS,
  EDIT_TECHNICIAN_SUCCESS,
  GET_EXPENSE_LIST_SUCCESS,
  GET_TECHNICIAN_FAILURE,
  GET_TECHNICIAN_NAMES_FAILURE,
  GET_TECHNICIAN_NAMES_SUCCCESS,
  GET_TECHNICIAN_SUCCESS,
  IS_LOADING,
  ORDER_DETAIL_SUCCESS,
  USER_DASHBOARD_SUCCESS,
} from '../constant';
import {
  addTechnicianApi,
  addTechnicianPaymentApi,
  deleteTechnicianApi,
  deleteTechnicianPaymentApi,
  editTechnicianApi,
  editTechnicianPaymentApi,
  editTechnicianStatusApi,
  getExpenseListApi,
  getOrderDetail,
  getTechnicianApi,
  getTechnicianNamesApi,
  userDasboardApi,
} from '../../services';
import { toast } from 'react-toastify';

export function* getTechnicianSaga(action: any): any {
  try {
    const response = yield call(getTechnicianApi, action.payload);
    yield put({
      type: GET_TECHNICIAN_SUCCESS,
      payload: response.data,
    });
  } catch (error: any) {
    toast.error(error?.response?.data?.message || "Something went wrong!");
    yield put({
      type: GET_TECHNICIAN_FAILURE,
      payload: error.response.data.message,
    });
  }
}

export function* addTechnicianSaga(action: any): any {
  yield put({
    type: IS_LOADING,
    payload: true,
  });
  try {
    const response = yield call(addTechnicianApi, action.payload.payload);
    const TechnicianDataResponse=yield call(getTechnicianApi,{
      id:action.payload.id,page:'1',searchTerm:'',rowsPerPage:10
    })

   yield put({
       type: GET_TECHNICIAN_SUCCESS,
     payload: TechnicianDataResponse.data,
     });
     yield put({
      type: ADD_TECHNICIAN_SUCCESS,
      payload: response.data.data,
    });
    yield put({
      type: IS_LOADING,
      payload: false,
    });
    toast(response.data.message);
    customHistory.push('/technicians');
    const dashboardDataResponse = yield call(userDasboardApi, {
      frequency: 'daily',
    });

    yield put({
      type: USER_DASHBOARD_SUCCESS,
      payload: dashboardDataResponse.data.data,
    });
     } catch (error: any) {
      toast.error(error?.response?.data?.message || "Something went wrong!");
    yield put({
      type: ADD_TECHNICIAN_FAILURE,
      payload: error.response.data.message,
    });
    yield put({
      type: IS_LOADING,
      payload: false,
    });
  }
}

export function* editTechnicianSaga(action: any): any {
  try {
    yield put({
      type: IS_LOADING,
      payload: true,
    });
    const response = yield call(editTechnicianApi, action.payload);
    const TechnicianDataResponse=yield call(getTechnicianApi,{
      id:action.payload.id,page:'1',searchTerm:'',rowsPerPage:10
    })
    yield put({
      type: GET_TECHNICIAN_SUCCESS,
    payload: TechnicianDataResponse.data,
    });
    yield put({
      type: EDIT_TECHNICIAN_SUCCESS,
      payload: response.data.data,
    });
    toast(response.data.message);
    yield put({
      type: IS_LOADING,
      payload: false,
    });
    customHistory.push('/technicians');
   } catch (error: any) {
    toast.error(error?.response?.data?.message || "Something went wrong!");
    yield put({
      type: EDIT_TECHNICIAN_FAILURE,
      payload: error.response.data.message,
    });
    yield put({
      type: IS_LOADING,
      payload: false,
    });
  }
}

export function* editTechnicianStatusSaga(action: any): any {
  try {
    const response = yield call(editTechnicianStatusApi, action.payload);
     yield put({
      type: EDIT_TECHNICIAN_STATUS_SUCCESS,
      payload: response.data.data,
    });
   
    const technicianList = store.getState().technician;
    const newList = technicianList?.technicianData?.data.map((item:any) => {
      if (item._id === response?.data?.data._id) {
        const updatedItem = {
          ...response?.data?.data,
        };
         return updatedItem;
      }
      return item;
    });
    const updatedTechnicianList={
       total:technicianList?.technicianData.total,
      data:newList
    }
    yield put({
      type: GET_TECHNICIAN_SUCCESS,
    payload: updatedTechnicianList,
    });
    toast(response.data.message);
    const dashboardDataResponse = yield call(userDasboardApi, {
      frequency: 'daily',
    });
    yield put({
      type: USER_DASHBOARD_SUCCESS,
      payload: dashboardDataResponse.data.data,
    });
   } catch (error: any) {
    toast.error(error?.response?.data?.message || "Something went wrong!");
    yield put({
      type: EDIT_TECHNICIAN_STATUS_FAILURE,
      payload: error.response.data.message,
    });
    toast(error.response.data.message);
  }
}

export function* getTechnicianNames(): any {
  try {
    const response = yield call(getTechnicianNamesApi);
    yield put({
      type: GET_TECHNICIAN_NAMES_SUCCCESS,
      payload: response.data.data,
    });
  } catch (error: any) {
    toast.error(error?.response?.data?.message || "Something went wrong!");
    yield put({
      type: GET_TECHNICIAN_NAMES_FAILURE,
      payload: error.response.data.message,
    });
    toast(error.response.data.message);
  }
}

export function* deleteTechnicianSaga(action: any): any {
  try {
    const response = yield call(deleteTechnicianApi, action.payload.payload);
     const TechnicianDataResponse=yield call(getTechnicianApi,{
      id:action.payload.id,page:'1',searchTerm:'',rowsPerPage:10
    })
       yield put({
         type: GET_TECHNICIAN_SUCCESS,
       payload: TechnicianDataResponse.data,
       });
    yield put({
      type: DELETE_TECHNICIAN_SUCCESS,
    });
    toast(response.data.message);
    const dashboardDataResponse = yield call(userDasboardApi, {
      frequency: 'daily',
    });
    yield put({
      type: USER_DASHBOARD_SUCCESS,
      payload: dashboardDataResponse.data.data,
    });
   
  } catch (error: any) {
    toast.error(error?.response?.data?.message || "Something went wrong!");
    yield put({
      type: DELETE_TECHNICIAN_FAILURE,
      payload: error.response.data.message,
    });
  }
}

export function* addTechnicianPaymentSaga(action: any): any {
  try {
    const response = yield call(
      addTechnicianPaymentApi,
      action.payload.payload
    );
    const orderDetailResponse = yield call(getOrderDetail, {
      _id: action.payload?._id,
    });
    const expenseDataResponse=yield call(getExpenseListApi, {
      id: action.payload.id,
      page: '1',
      searchTerm: '',
      rowsPerPage: 10,
      status: '', minExpense: '', maxExpense: '', fromDate: '',
      toDate: '',
    });
    yield put({
      type: GET_EXPENSE_LIST_SUCCESS,
      payload: expenseDataResponse.data,
    });
    yield put({
      type: ORDER_DETAIL_SUCCESS,
      payload: orderDetailResponse.data.data,
    });
      
    yield put({
      type: ADD_TECHNICIAN_PAYMENT_SUCCESS,
      payload: response.data.data,
    });
    toast(response.data.message);
  } catch (error: any) {
    toast.error(error?.response?.data?.message || "Something went wrong!");
    yield put({
      type: ADD_TECHNICIAN_PAYMENT_FAILURE,
      payload: error.response.data.message,
    });
    yield put({
      type: IS_LOADING,
      payload: false,
    });
  }
}

export function* editTechnicianPaymentSaga(action: any): any {
  try {
    const response = yield call(
      editTechnicianPaymentApi,
      action.payload.payload
    );
    const orderDetailResponse = yield call(getOrderDetail, {
      _id: action.payload?._id,
    });
    const expenseDataResponse=yield call(getExpenseListApi, {
      id: action.payload.id,
      page: '1',
      searchTerm: '',
      rowsPerPage: 10,
      status: '', minExpense: '', maxExpense: '', fromDate: '',
      toDate: '',
    });
    yield put({
      type: GET_EXPENSE_LIST_SUCCESS,
      payload: expenseDataResponse.data,
    });
    yield put({
      type: ORDER_DETAIL_SUCCESS,
      payload: orderDetailResponse.data.data,
    });
    yield put({
      type: EDIT_TECHNICIAN_PAYMENT_SUCCESS,
      payload: response.data.data,
    });
    toast(response.data.message);
  } catch (error: any) {
    toast.error(error?.response?.data?.message || "Something went wrong!");
    yield put({
      type: EDIT_TECHNICIAN_PAYMENT_FAILURE,
      payload: error.response.data.message,
    });
    yield put({
      type: IS_LOADING,
      payload: false,
    });
  }
}

export function* deleteTechnicianPaymentSaga(action: any): any {
  try {
    const response = yield call(deleteTechnicianPaymentApi, action.payload.payload);
    const orderDetailResponse = yield call(getOrderDetail,{_id: action.payload?.payload?._id})
    yield put({
      type: ORDER_DETAIL_SUCCESS,
      payload: orderDetailResponse?.data?.data,
    });
    const expenseDataResponse=yield call(getExpenseListApi, {
      id: action.payload.id,
      page: '1',
      searchTerm: '',
      rowsPerPage: 10,
      status: '', minExpense: '', maxExpense: '', fromDate: '',
      toDate: '',
    });
    yield put({
      type: GET_EXPENSE_LIST_SUCCESS,
      payload: expenseDataResponse?.data,
    });
    yield put({
      type: DELETE_TECHNICIAN_PAYMENT_SUCCESS,
    });
    toast(response.data.message);
  } catch (error: any) {
    toast.error(error?.response?.data?.message || "Something went wrong!");
    yield put({
      type: DELETE_TECHNICIAN_PAYMENT_FAILURE,
      payload: error.response.data.message,
    });
  }
}
